<template>
	<div class="appMain">
		<div class="title">集体报名</div>
		<div></div>
		<img src="../../assets/groupRegistration/0.png" alt="" srcset="" @click="handleDownload" class="img">
		<div class="content">
			<div class="inner_border">
				<div v-for="(item,index) in imgList" :key="index" class="item">
					<img :src="item.photoUrl" alt="" srcset="" width="100%">
					<img src="../../assets/groupRegistration/icon_right.png" alt="" srcset="" v-if="index%2 != 0"
						class="icon_img">
				</div>
				<div class="goBack" @click="goBack">返回</div>
			</div>
		</div>
	</div>
</template>
<script>
	const newLocal = location.host;
	export default {
		data() {
			return {
				host: newLocal,
				imgList: []
			}
		},
		created() {
			this.getCollective()
		},
		methods: {
			goBack(){
				this.$router.go(-1)
			},
			// 步骤排序
			compare(propertyName) {
				return function(object1, object2) {
					var value1 = object1[propertyName];
					var value2 = object2[propertyName];
					return value1 - value2
				};
			},
			// 下载模板
			handleDownload() {
				let dload = document.createElement("a");
				dload.download = 'xxx单位学员及报名负责人信息表'; // 设置下载的文件名，默认是'下载'
				dload.href = `https://api.jjyxt.cn/webSite/userWebsite/downloadTemplate?origin=${location.host}`;
				document.body.appendChild(dload);
				dload.click();
				dload.remove(); // 下载之后把创建的元素删除
			},
			// 获取集体报名步骤
			getCollective() {
				this.$http.post('getCollective').then(res => {
					if (res.code == 200) {
						this.imgList = res.data.sort(this.compare('step'))
					} else {
						this.$message.error(res.message)
					}
				})
			}
		}
	}
</script>
<style lang="less" scoped>
.goBack{
	position: absolute;
	bottom: 8px;
	right: 50px;
	width: 70px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	border-radius: 10px;
	background-color: #FD9E31;
	cursor: pointer;
}
	.appMain {
		// height: 100%;
		background: url('../../assets/groupRegistration/bg.png') no-repeat;
		background-size: 100% 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.title {
		font-size: 48px;
		font-family: Source Han Sans CN;
		font-weight: bold;
		color: #FBEAC1;
		// text-align: center;
		padding-top: 58px;
	}

	.img {
		cursor: pointer;
		margin-top: 68px;
	}

	.content {
		margin-top: 73px;
		margin-bottom: 200px;
		width: 60%;
		// height: 774px;
		background: linear-gradient(0deg, #FCFCD6, #EABE5A);
		box-shadow: 0px 3px 5px 0px rgba(1, 24, 25, 0.49);
		border-radius: 8px;
		padding: 4px;

		.inner_border {
			position: relative;
			// height: 100%;
			border: 6px solid #930207;
			border-radius: 8px;
			padding: 56px;
			display: flex;
			flex-wrap: wrap;
			// align-items: center;
			justify-content: space-between;

			.item {
				width: 47%;
				margin-bottom: 37px;
				position: relative;

				.icon_img {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: -8%;
				}

				&:nth-child(n+2) {
					margin-bottom: 0;
				}

				// img{}
			}
		}
	}

	// .contentTwo {
	// 	width: 780px;
	// 	height: 94px;
	// 	border: 4px solid #FFFFFF;
	// 	background: linear-gradient(0deg, #FCFCD6, #EABE5A);
	// 	box-shadow: 0px 3px 5px 0px rgba(1, 24, 25, 0.49);
	// 	border-radius: 47px;
	// 	margin: 0 auto;
	// 	margin-top: 68px;
	// 	opacity: 0.5;
	// 	position: relative;
	// 	line-height: 90px;
	// 	font-size: 28px;
	// 	font-family: Source Han Sans CN;
	// 	font-weight: bold;
	// 	color: rgb(170, 14, 14);
	// 	padding-left: 100px;

	// 	span {
	// 		position: absolute;
	// 		left: 30px;
	// 		top: 35px;
	// 		width: 15px;
	// 		height: 15px;
	// 		background: linear-gradient(0deg, #FCFCD6, #EABE5A);
	// 		box-shadow: 0px 3px 5px 0px rgba(1, 24, 25, 0.49);
	// 		border-radius: 50%;
	// 	}

	// 	a {
	// 		position: absolute;
	// 		right: -4px;
	// 		top: -5px;
	// 		width: 140px;
	// 		height: 94px;
	// 		background: linear-gradient(0deg, #FCFCD6, #EABE5A);
	// 		border: 4px solid #FFFFFF;
	// 		box-shadow: 0px 3px 5px 0px rgba(1, 24, 25, 0.49);
	// 		border-radius: 47px;
	// 		font-size: 27px;
	// 		font-family: Source Han Sans CN;
	// 		font-weight: bold;
	// 		color: #910106;
	// 		line-height: 90px;
	// 		text-align: center;
	// 		cursor: pointer;
	// 	}
	// }
</style>
